.dash_title {
  font-weight: bolder;
  font-size: 20px;
  margin-right: 10px;
}

.search_focus {
  padding: 18px 12px !important;
  width: 75% !important;
  margin-left: auto !important;
  margin-bottom: 16px;
}

.head_margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_focus:focus {
  border: 2px solid #2684ff !important;
  /* border-color: rgb(0 , 0 ,0)!important; */
  border-radius: 4px !important;
}

/* .headerBack{
  background-color: #0A4D99 !important;
} */

.plus_add_btn {
  margin-right: 15px;
  margin-bottom: 10px;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

/* .plus_add_btn :focus{
  outline: none !important;
} */

.row .sec_border {
  background: #fff;
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0 0 0.3125rem #1718180d, 0 0.0625rem 0.125rem #00000026;
}
.dash_crd_bdy {
  text-align: center;
  /* background: #4e659e; */
  padding: 15px 10px;
  background: #0a4d99;
  /* linear-gradient(45deg, #5c258d, #4389a2); */
  color: #e9e7e7;
  border-radius: 10px;
  border: none !important;
}
/* #e3ddb3 */

.dash_crd_txt {
  font-size: 20px;
}

.add_product_design {
  background: #0a4d99 !important;
  color: #ffffff !important;
  margin: 5px 0 !important;
  font-weight: bold !important;
  cursor: pointer;
}

.sidebar-wrapper .active {
  background-color: #0a4d99 !important;
}

.dash_visitor_title {
  font-weight: bold;
  font-size: 17px;
}

.Step_font {
  font-weight: bold;
  font-size: 20px;
}

/* .perc_border{
  border: 1px solid gray;
  border-radius: 4px;
  padding: 9px;
} */

.main_cat {
  font-weight: bold;
  margin: 20px 0 10px;
}

.step_pad {
  padding: 10px 10px !important;
}

@media only screen and (max-width: 1270px) and (min-width: 990px) {
  .step_body {
    height: 110px;
  }
  .step_pad {
    padding: 16px 10px !important;
  }
}

@media only screen and (max-width: 610px) and (min-width: 576px) {
  .dash_visitor_title {
    font-size: 16px;
  }
}
