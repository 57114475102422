.import_btn {
  display: flex;
  justify-content: flex-end;
}
.cancel_btn {
  margin-right: "12px";
}
.cancle_margin {
  margin-right: "12px";
}

.product_img {
  width: "100px" !important;
  height: "100px" !important;
  object-fit: cover !important;
}
.product_img img {
  width: 100% !important;
}
.card {
  border-radius: 0.75rem !important;
  box-shadow: 0 0 0.3125rem #1718180d, 0 0.0625rem 0.125rem #00000026 !important;
  border: none !important;
}

.editor-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.editor-display img {
  width: 300px;
}
.react-editor-quill-news {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px 20px;
}
