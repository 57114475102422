.thumbnail_img img{
width: 60px;
height: 60px;
display: block;
border: 1px solid #ddd;
border-radius: 4px;
padding: 3px;
margin-top: 5px;
/* object-fit:cover */
}

.form:focus{
    border:2px solid rgb(6, 6, 112);
    box-shadow: none!important;
}

.sec_height{
    height:35px;
}
.sec_border{
    border-bottom: 1px solid #dee2e6!important;
    margin: 10px 0;
}

