.Track_filter {
  display: flex;
  justify-content: flex-end;
}
.filter_btn {
  border: none;
  background-color: #343940;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  letter-spacing: 1px;
  font-size: 18px;
}
.form_datepicker {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}
